import { EventSumInfo } from "@api/types/backendTypes";
import { getNearestAxisValue } from "@components/ChannelControlMetrics/ConversionValueChart";
import {
  dashboardModeAtom,
  endTimeAtom,
  ppsMetricAtom,
  startTimeAtom,
} from "atoms";
import {
  chartTooltipStyles,
  CONSTANT_LABELS,
  GENERAL_CONSTANT_LABELS,
} from "constants/constants";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useCurrency from "../../../lib/hooks/use-currency";
import * as RechartsPrimitive from "recharts";
import { cn } from "@lib/utils";
import { mapChannelToColor } from "@api/services/analytics";
import useFormat from "@lib/hooks/use-format";

type Props = {
  dailyChannelData: { [key: string]: MarketingChannelOverviewInterface[] };
  ppsWithOrderData: Array<Record<string, any>>;
  title: string;
};

export function PpsChart({ dailyChannelData, ppsWithOrderData, title }: Props) {
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const [ppsMetric] = useAtom(ppsMetricAtom);
  const { formatNumber } = useFormat();
  const maxValue = ppsWithOrderData.reduce((max, item) => {
    for (const [key, value] of Object.entries(item)) {
      if (key === "date") continue;
      if (value[ppsMetric] > max) {
        max = value[ppsMetric];
      }
    }
    return max;
  }, 0);

  const channels = ppsWithOrderData.reduce((acc: string[], item) => {
    for (const key of Object.keys(item)) {
      if (key === "date") continue;
      if (acc.includes(key)) continue;
      acc.push(key);
    }
    return acc;
  }, [] as string[]);

  const colors = channels.reduce(
    (acc, channel) => {
      const regularName = CONSTANT_LABELS[channel] ?? channel;
      acc[channel] = mapChannelToColor(regularName);
      return acc;
    },
    {} as { [key: string]: string }
  );

  const maxYDomain = getNearestAxisValue(maxValue);

  const [xAxisTicks, setXAxisTicks] = useState(Object.keys(dailyChannelData));
  const { calculateCurrencyConvertion, globalCurrencySymbol } = useCurrency();
  useEffect(() => {
    function setXAxisTicksHandler() {
      const ppsChart = document.getElementById("pps-chart-container");
      const width = ppsChart?.clientWidth ?? 0;
      const maxAmountOfTicks = Math.floor((width - 300) / (36 + 15)); // the labels are roughly 36px wide + a 15px spacing
      const dateKeys = Object.keys(dailyChannelData).length;
      const keysIndexToChoose = Math.ceil(dateKeys / maxAmountOfTicks);
      const keysToDisplay = Object.keys(dailyChannelData)
        .map((el, index) => (index % keysIndexToChoose === 0 ? el : null))
        .filter((el) => Boolean(el)) as string[];
      setXAxisTicks(keysToDisplay);
    }
    setXAxisTicksHandler();
    window.addEventListener("resize", setXAxisTicksHandler);
    return () => {
      window.removeEventListener("resize", setXAxisTicksHandler);
    };
  }, [dailyChannelData]);
  return (
    <ResponsiveContainer width="100%" height={350} id={"pps-chart-container"}>
      <ComposedChart data={ppsWithOrderData}>
        <defs>
          {Object.entries(colors).map(([channel, color], index) => (
            <linearGradient
              id={`color-${channel}`}
              key={`color-${channel}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor={color} stopOpacity={1} />
              <stop offset="95%" stopColor={color} stopOpacity={1} />
            </linearGradient>
          ))}
        </defs>

        <YAxis
          tickFormatter={(value: string) =>
            ["roas", "purchaseCount"].includes(ppsMetric)
              ? `${formatNumber(parseFloat(value.toString() ?? "0"))}`
              : `${calculateCurrencyConvertion(
                  parseFloat(value.toString() ?? "0"),
                  0,
                  false
                )}`
          }
          tickSize={0}
          axisLine={false}
          tickMargin={0}
          tickCount={6}
          width={50}
          ticks={[1, 2, 3, 4, 5].map(
            (el) => Math.round((maxYDomain / 5) * el * 100) / 100
          )}
          domain={[0, maxYDomain]}
        >
          <Label
            value={
              ["roas", "purchaseCount"].includes(ppsMetric)
                ? CONSTANT_LABELS[ppsMetric] ?? ppsMetric
                : `${
                    CONSTANT_LABELS[ppsMetric] ?? ppsMetric
                  } in ${globalCurrencySymbol}`
            }
            offset={20}
            angle={-90}
            position={{
              x: 10,
              y: 160,
            }}
            textAnchor="middle"
          />
        </YAxis>

        <XAxis
          dataKey="date"
          tickSize={0}
          tickMargin={20}
          axisLine={false}
          tickFormatter={(value: string) =>
            value ? String(value)?.split("-").slice(1).reverse().join("/") : ""
          }
          ticks={xAxisTicks}
        />
        <CartesianGrid stroke="var(--chart-grid)" vertical={false} />
        <Tooltip
          contentStyle={chartTooltipStyles}
          formatter={(value: string, name: string) => {
            return [
              ["roas", "purchaseCount"].includes(ppsMetric)
                ? formatNumber(parseFloat(value), 2)
                : `${calculateCurrencyConvertion(parseFloat(value), 2)}`,
              dashboardMode === "general"
                ? `${GENERAL_CONSTANT_LABELS[name]} ${GENERAL_CONSTANT_LABELS[ppsMetric]}`
                : `${CONSTANT_LABELS[name]} ${CONSTANT_LABELS[ppsMetric]}`,
            ];
          }}
        />
        <Legend content={<ChartLegend />} />

        {channels.map((channel) => (
          <Line
            type="monotone"
            dataKey={`${channel}.${ppsMetric}`}
            name={channel}
            key={`${channel}.${ppsMetric}`}
            stroke={colors[channel]}
            strokeWidth={2}
            connectNulls
            dot={false}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

const ChartLegend = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> &
    Pick<RechartsPrimitive.LegendProps, "payload" | "verticalAlign"> & {
      hideIcon?: boolean;
      nameKey?: string;
    }
>(
  (
    { className, hideIcon = false, payload, verticalAlign = "bottom", nameKey },
    ref
  ) => {
    if (!payload?.length) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={cn(
          "flex items-center justify-center gap-4",
          verticalAlign === "top" ? "pb-3" : "pt-3",
          className
        )}
      >
        {payload.map((item) => {
          const key = `${nameKey || item.dataKey || "value"}`;

          return (
            <div
              key={item.value}
              className={cn(
                "flex items-center gap-1.5 [&>svg]:h-4 [&>svg]:w-4 [&>svg]:text-muted-foreground"
              )}
            >
              <div
                className="h-3 w-3 shrink-0 rounded-[2px]"
                style={{
                  backgroundColor: item.color,
                }}
              />
              {CONSTANT_LABELS[item?.value]}
            </div>
          );
        })}
      </div>
    );
  }
);
ChartLegend.displayName = "ChartLegend";
