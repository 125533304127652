import React from "react";

const DecifyIconDark = () => {
  return (
    <svg
      width="191"
      height="48"
      viewBox="0 0 191 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_132_89)">
        <path d="M0 0V15.0243H25.0406V40.0649H40.0649V0H0Z" fill="white" />
        <path d="M15.0243 25.0406H0V40.0649H15.0243V25.0406Z" fill="white" />
        <path
          d="M83.7581 23.574C83.7581 34.1493 77.9813 40.0659 67.5923 40.0659H56.2716V6.98889H67.3128C77.8416 6.98889 83.7581 12.952 83.7581 23.574ZM67.3128 34.7083C74.161 34.7083 77.5155 31.0743 77.5155 23.574C77.5155 16.0736 74.161 12.3464 67.3128 12.3464H62.3745V34.7083H67.3128Z"
          fill="white"
        />
        <path
          d="M98.479 14.8158C104.815 14.8158 109.986 19.102 110.079 27.8601L110.126 29.3508H92.5625C92.842 33.4506 94.9848 35.8729 98.479 35.8729C100.715 35.8729 102.719 34.6152 103.604 32.4719L109.753 32.9377C108.309 37.6897 103.837 40.6244 98.479 40.6244C91.1184 40.6244 86.2733 35.5464 86.2733 27.7199C86.2733 19.8934 91.1184 14.8154 98.479 14.8154V14.8158ZM103.883 25.3441C103.417 21.1512 101.088 19.5673 98.479 19.5673C95.2182 19.5673 93.1215 21.7568 92.6556 25.3441H103.883Z"
          fill="white"
        />
        <path
          d="M124.754 19.7076C120.934 19.7076 118.744 22.6428 118.744 27.7204C118.744 32.798 120.934 35.7332 124.754 35.7332C127.503 35.7332 129.692 34.0096 130.158 30.7017L136.401 30.9812C135.702 36.9443 130.857 40.6249 124.754 40.6249C117.393 40.6249 112.548 35.5468 112.548 27.7204C112.548 19.8939 117.393 14.8159 124.754 14.8159C130.67 14.8159 135.562 18.3567 136.214 24.133L130.018 24.4591C129.552 21.3841 127.363 19.7071 124.754 19.7071V19.7076Z"
          fill="white"
        />
        <path
          d="M138.824 6.98889H145.066V11.9272H138.824V6.98889ZM144.973 15.3748V40.0659H138.917V15.3748H144.973Z"
          fill="white"
        />
        <path
          d="M160.533 6.98889H165.191V11.6943H161.278C159.694 11.6943 158.623 12.8589 158.623 14.7688V16.0731H164.819V20.7319H158.623V40.0654H152.613V20.7319H149.072V16.0731H152.613V14.7688C152.613 9.78389 155.082 6.98889 160.533 6.98889Z"
          fill="white"
        />
        <path
          d="M172.272 15.3749L178.794 33.7301L185.13 15.3749H191L181.17 42.675C180.145 45.703 178.095 47.0543 174.788 47.0543H170.734V42.4421H173.763C175.114 42.4421 175.859 41.9763 176.371 40.8583L177.07 39.0881H175.3L166.356 15.3753H172.272V15.3749Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_89">
          <rect width="191" height="47.0538" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DecifyIconDark;
