import { EventSumResult } from "@api/types/backendTypes";
import { CardContainer } from "@components/Cards/CardContainer";
import { PerformanceCard } from "@components/Cards/PerformanceCard";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { dashboardModeAtom, newVsReturningAtom } from "atoms";
import {
  MarketingChannelOverviewInterface,
  MarketingOverviewInterface,
} from "interface/MarketingChannelOverviewInterface";
import { PerformanceCardInterface } from "interface/PerformanceCardInterface";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import { CACDevelopmentChart } from "./CACDevelopmentChart";
import { RevenueInfoContainer } from "./RevenueInfoContainer";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import useCurrency from "../../../lib/hooks/use-currency";
import useFormat from "@lib/hooks/use-format";
import { EventsResult } from "@lib/api-hooks";
import { getCorrelation } from "@components/NumberDisplays/calculations";

type Props = {
  data: MarketingOverviewInterface & {
    compared?: MarketingOverviewInterface | null;
  };
  dailyChannelData: {
    new: { [key: string]: MarketingChannelOverviewInterface[] };
    returning: { [key: string]: MarketingChannelOverviewInterface[] };
    all: { [key: string]: MarketingChannelOverviewInterface[] };
    compared?: {
      new: { [key: string]: MarketingChannelOverviewInterface[] };
      returning: { [key: string]: MarketingChannelOverviewInterface[] };
      all: { [key: string]: MarketingChannelOverviewInterface[] };
    };
  };
  loading?: boolean;
  storeEventsData?: EventsResult;
  trackedOrdersRatio?: { [date: string]: number };
};

/**
 * Implements the component for the marketing performance.
 *
 * @return {CustomElement}
 */
export function MarketingPerformance({
  data,
  loading,
  storeEventsData,
  dailyChannelData,
}: Props) {
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const [dashboardMode] = useAtom(dashboardModeAtom);

  const { formatNumber } = useFormat();
  const { calculateCurrencyConvertion } = useCurrency();
  const performanceCardsData = useMemo<PerformanceCardInterface[]>(() => {
    const cards = [
      {
        title: "Ad Spend",
        value: `${calculateCurrencyConvertion(data.spend, 2)}`,
        comparedValue: `${calculateCurrencyConvertion(
          data.compared?.spend,
          2
        )}`,
        compared: Boolean(storeEventsData?.compared),
        isIncreasing: Boolean(
          data.spend &&
            data.compared?.spend &&
            data.spend > data.compared?.spend
        ),
        rate: getCorrelation(data.spend, data.compared?.spend, 2),
      },
      {
        title: dashboardMode === "general" ? "Conv. Value" : "Store Revenue",
        value: `${calculateCurrencyConvertion(
          storeEventsData?.totals?.total_amount ?? data.purchaseAmount,
          2
        )} `,
        comparedValue: `${calculateCurrencyConvertion(
          storeEventsData?.compared?.totals?.total_amount ??
            data.compared?.purchaseAmount,
          2
        )} `,
        compared: Boolean(storeEventsData?.compared),
        isIncreasing: Boolean(
          storeEventsData?.totals?.total_amount &&
            storeEventsData?.compared?.totals?.total_amount &&
            storeEventsData?.totals?.total_amount >
              storeEventsData?.compared?.totals?.total_amount
        ),
        rate: storeEventsData?.totals?.total_amount
          ? getCorrelation(
              storeEventsData?.totals?.total_amount,
              storeEventsData?.compared?.totals?.total_amount,
              2
            )
          : 0,

        tooltip: (
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="h-5 w-5 text-primary" />
            </TooltipTrigger>
            <TooltipContent>
              <p className="max-w-lg">
                The{" "}
                {dashboardMode === "general" ? "conversion value" : "revenue"}{" "}
                you see here may differ from what your{" "}
                {dashboardMode === "general" ? "" : "shop "}system reports, as
                we wait for{" "}
                {dashboardMode === "general" ? "conversions" : "orders"} to be
                marked as paid by your system before reporting them. Also, we do
                not include refunds.
              </p>
            </TooltipContent>
          </Tooltip>
        ),
      },
      {
        title: dashboardMode === "general" ? "CPA" : "CPO",
        value: `${calculateCurrencyConvertion(
          storeEventsData?.totals?.total_count
            ? data.spend / storeEventsData?.totals?.total_count
            : data.cpo,
          2
        )} `,
        comparedValue: `${calculateCurrencyConvertion(
          storeEventsData?.compared?.totals?.total_count && data.compared?.spend
            ? data.compared?.spend /
                storeEventsData?.compared?.totals?.total_count
            : data.compared?.cpo,
          2
        )} `,
        compared: Boolean(storeEventsData?.compared),
        isIncreasing: Boolean(
          storeEventsData?.totals?.total_count &&
            storeEventsData?.compared?.totals?.total_count &&
            data.spend &&
            data.compared?.spend &&
            data.spend / storeEventsData?.totals?.total_count <
              data.compared?.spend /
                storeEventsData?.compared?.totals?.total_count
        ),
        rate:
          storeEventsData?.totals?.total_count &&
          storeEventsData?.compared?.totals?.total_count &&
          data.spend &&
          data.compared?.spend
            ? getCorrelation(
                data.spend / storeEventsData?.totals?.total_count,
                data.compared?.spend /
                  storeEventsData?.compared?.totals?.total_count,
                2
              )
            : 0,
      },
      {
        title: "MER",
        value: formatNumber(
          storeEventsData?.totals?.total_amount
            ? storeEventsData?.totals?.total_amount / data.spend
            : data.roas,
          2
        ),
        comparedValue: formatNumber(
          (storeEventsData?.compared?.totals?.total_amount &&
          data.compared?.spend
            ? storeEventsData?.compared?.totals?.total_amount /
              data.compared?.spend
            : data.compared?.roas) ?? 0,
          2
        ),
        compared: Boolean(storeEventsData?.compared),
        isIncreasing: Boolean(
          storeEventsData?.totals?.total_amount &&
            storeEventsData?.compared?.totals?.total_amount &&
            data.spend &&
            data.compared?.spend &&
            storeEventsData?.totals?.total_amount / data.spend >
              storeEventsData?.compared?.totals?.total_amount /
                data.compared?.spend
        ),
        rate:
          storeEventsData?.totals?.total_amount &&
          storeEventsData?.compared?.totals?.total_amount &&
          data.spend &&
          data.compared?.spend
            ? getCorrelation(
                storeEventsData?.totals?.total_amount / data.spend,
                storeEventsData?.compared?.totals?.total_amount /
                  data?.compared?.spend,
                2
              )
            : 0,
      },
      {
        title: "CAC",
        value:
          data.cac || data.compared?.cac
            ? `${calculateCurrencyConvertion(data.cac ?? 0, 2)} `
            : "-",
        comparedValue: data.compared?.cac
          ? `${calculateCurrencyConvertion(data.compared?.cac ?? 0, 2)} `
          : "-",
        compared: Boolean(storeEventsData?.compared),
        isIncreasing: Boolean(
          data.cac && data.compared?.cac && data.cac < data.compared?.cac
        ),
        rate: getCorrelation(data.cac ?? 0, data.compared?.cac, 2),
        hideRate: newVsReturning === "default",
      },
      {
        title: "New Customer Rate",
        value:
          data.ncr || data?.compared?.ncr
            ? `${formatNumber((data.ncr ?? 0) * 100, 2)}%`
            : "-",
        comparedValue: data.compared?.ncr
          ? `${formatNumber((data.compared?.ncr ?? 0) * 100, 2)}%`
          : "-",
        compared: Boolean(storeEventsData?.compared),
        isIncreasing: Boolean(
          data.ncr && data.compared?.ncr && data.ncr > data.compared?.ncr
        ),
        rate: getCorrelation(data.ncr ?? 0, data.compared?.ncr, 4),
        hideRate: newVsReturning === "default",
      },
    ];

    return cards;
  }, [
    calculateCurrencyConvertion,
    data.spend,
    data.compared?.spend,
    data.compared?.purchaseAmount,
    data.compared?.cpo,
    data.compared?.roas,
    data.compared?.cac,
    data.compared?.ncr,
    data.purchaseAmount,
    data.cpo,
    data.roas,
    data.cac,
    data.ncr,
    storeEventsData?.compared,
    storeEventsData?.totals?.total_amount,
    storeEventsData?.totals?.total_count,
    dashboardMode,
    formatNumber,
    newVsReturning,
  ]);

  return (
    <CardContainer>
      <div className="w-full">
        <div className={`grid grid-cols-12 items-stretch gap-2`}>
          {loading
            ? Array(6)
                .fill(1)
                .map((el, index) => {
                  return (
                    <div
                      className={`col-span-5 2xl:col-span-2 bg-gray-100 dark:bg-gray-800 p-4 rounded-md h-[112px]`}
                      key={`skeleton${index}`}
                    >
                      <div role="status" className="animate-pulse">
                        <div className="h-4 w-24 rounded-md bg-gray-200 dark:bg-gray-700" />
                        <div className="h-5 w-16 mt-4 rounded-md bg-gray-200 dark:bg-gray-700" />
                      </div>
                    </div>
                  );
                })
            : null}
          {!loading &&
            performanceCardsData &&
            performanceCardsData.map(
              (card: PerformanceCardInterface, index) => (
                <div
                  className="col-span-6 2xl:col-span-2 "
                  key={`card${index}`}
                >
                  <PerformanceCard {...card} chartData={undefined} isReduced />
                </div>
              )
            )}
        </div>
      </div>
      <div className="w-full mt-8">
        <RevenueInfoContainer
          dailyChannelData={
            dailyChannelData[
              newVsReturning === "default" ? "all" : newVsReturning
            ]
          }
          dailyStoreEventsData={storeEventsData?.daily}
          dailyComparedChannelData={
            dailyChannelData.compared
              ? dailyChannelData.compared[
                  newVsReturning === "default" ? "all" : newVsReturning
                ]
              : undefined
          }
          dailyComparedStoreEventsData={storeEventsData?.compared?.daily}
          loading={loading}
        />
      </div>
      <div className="mt-8">
        <CACDevelopmentChart
          dailyChannelData={dailyChannelData}
          dailyStoreEventsData={storeEventsData?.daily}
          dailyComparedStoreEventsData={storeEventsData?.compared?.daily}
          loading={loading}
        />
      </div>
    </CardContainer>
  );
}
