import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { ShopConnectorAPIError } from "@api/types/backendTypes";
import { PpsData } from "./types";

export const FetchPpsDataSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
  csids: z.array(z.string()),
  created_at_min: z.string(),
  created_at_max: z.string(),
});

export type FetchPpsDataProps = z.infer<typeof FetchPpsDataSchema>;

async function fetchPpsData({
  token,
  csids,
  created_at_min,
  created_at_max,
}: FetchPpsDataProps): Promise<Array<PpsData>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_SHOP_CONNECTOR_V2_BASE_URL}/metadata/pps/list`,
      {
        params: {
          csids: csids.join(","),
          created_at_min,
          created_at_max,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ShopConnectorAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unknown error occurred!");
  }
}

export type UsePpsDataOptions = {
  enabled?: boolean;
  csids: Array<string>;
  created_at_min: string;
  created_at_max: string;
};

export function usePpsData(options: UsePpsDataOptions) {
  const { data } = useAuth();
  const hasAllProps = Boolean(
    options?.csids && options?.created_at_max && options?.created_at_min
  );
  return useQuery({
    queryKey: [
      "ppsData",
      options.created_at_max,
      options.created_at_min,
      options.csids,
    ],
    queryFn: () =>
      fetchPpsData({
        token: data?.token!,
        created_at_max: options.created_at_max,
        created_at_min: options.created_at_min,
        csids: options.csids,
      }),
    ...options,
    refetchOnWindowFocus: false,
    staleTime: 0, // 0 seconds needed to overwrite global settings
    gcTime: 0, // 0 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token && hasAllProps
        : !!data?.token && hasAllProps,
  });
}
