import DecifyIconDark from "@components/Layout/icons/decify-icon-dark";
import { Button } from "@components/ui/button";
import React from "react";

const DecifyIntegrationPlaceholder = () => {
  return (
    <div className="w-full border-black p-12 flex flex-col rounded-lg bg-gray-100 dark:bg-gray-800">
      <div className="flex flex-col items-center justify-center text-center max-w-lg mx-auto">
        <DecifyIconDark />
        <p className="mt-8">
          Import your post purchase survey data to Tracify and get more insight
          into your paid and organic channels.
        </p>
        <a href="https://tracify.ai" target="_blank">
          <Button variant="primary" className="mt-8 min-w-40">
            Learn More
          </Button>
        </a>
      </div>
      <div className="text-center max-w-3xl mx-auto">
        <p className="mt-6 text-foreground-soft text-sm">
          Decify is an add-on in cooperation with Tracify. Decify survey data is
          not included in Tracify&apos;s attribution data.
        </p>
      </div>
    </div>
  );
};

export default DecifyIntegrationPlaceholder;
