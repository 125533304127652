import React, { useEffect, useMemo } from "react";
import { RevenueChart } from "./RevenueChart";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { BaseOrder, BaseScore, EventSumInfo } from "@api/types/backendTypes";
import { useAtom } from "jotai";
import {
  dashboardModeAtom,
  endTimeAtom,
  newVsReturningAtom,
  ppsMetricAtom,
  startTimeAtom,
} from "atoms";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { PPS_METRICS_OPTIONS } from "constants/constants";
import { PpsChart } from "./PpsChart";
import { usePpsData } from "@lib/api-hooks/usePpsData/usePpsData";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { PpsData } from "@lib/api-hooks/usePpsData/types";
import {
  calculateAOV,
  calculateCAC,
  calculateCPO,
  calculateROAS,
} from "@lib/util-functions";
import { CardContainer } from "@components/Cards/CardContainer";
import DecifyIntegrationPlaceholder from "./DecifyIntegrationPlaceholder";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type Props = {
  dailyChannelData: { [date: string]: MarketingChannelOverviewInterface[] };
  dailyOrderData: { [date: string]: Map<string, BaseOrder> };
  ppsData?: PpsData[];
  loading?: boolean;
};

export function PpsDataContainer({
  dailyChannelData,
  dailyOrderData,
  loading,
  ppsData,
}: Props) {
  const [ppsMetric, setPpsMetric] = useAtom(ppsMetricAtom);
  const [newVsReturning] = useAtom(newVsReturningAtom);

  useEffect(() => {
    if (newVsReturning === "default" && ppsMetric === "cac") {
      setPpsMetric("purchaseCount");
    }
  }, [newVsReturning, ppsMetric, setPpsMetric]);

  const ppsWithOrderData: Array<Record<string, any>> = useMemo(() => {
    if (!ppsData || ppsData.length === 0) return [];
    if (loading) return [];
    const ppsArr = [];
    const channels: string[] = [];
    const hiveOrderCount =
      Object.values(dailyOrderData ?? {})?.reduce(
        (acc, curr) => acc + curr.size,
        0
      ) ?? 0;
    const ppsOrderCount = ppsData?.length ?? 0;
    const ppsRatio = Math.min(
      hiveOrderCount > 0 ? ppsOrderCount / hiveOrderCount : 0,
      1
    ); // ppsRatio cannot be greater than 1
    for (const [date, orders] of Object.entries(dailyOrderData)) {
      const initialData = {
        purchaseAmount: 0,
        purchaseCount: 0,
        nvr_purchaseCount: 0,
        roas: 0,
        aov: 0,
        cpo: 0,
        cac: 0,
      };
      const data: Record<string, any> = {
        date,
      };

      for (const order of orders.values()) {
        const ppsOrder = ppsData?.find((pps) => pps.order_id === order.orderId);
        // TODO: add spend to order data somehow
        if (ppsOrder) {
          if (!channels.includes(ppsOrder.PPS_SOURCE)) {
            channels.push(ppsOrder.PPS_SOURCE);
          }
          if (!data[ppsOrder.PPS_SOURCE]) {
            data[ppsOrder.PPS_SOURCE] = { ...initialData };
          }
          data[ppsOrder.PPS_SOURCE].purchaseAmount += order.purchaseAmount;
          data[ppsOrder.PPS_SOURCE].purchaseCount += order.purchaseCount;
          data[ppsOrder.PPS_SOURCE].nvr_purchaseCount +=
            order.nvr_purchaseCount;
        }
      }
      for (const [key, value] of Object.entries(data)) {
        if (key === "date") continue;
        const spend = dailyChannelData[date]?.find(
          (channel) => channel.provider === key
        )?.spend;
        value.spend = (spend ?? 0) * ppsRatio;

        data[key] = {
          ...value,
          aov: calculateAOV(value as BaseScore),
          roas: calculateROAS(value as BaseScore),
          cpo: calculateCPO(value as BaseScore),
          cac: calculateCAC(value as BaseScore, newVsReturning === "new"),
        };
      }
      ppsArr.push(data);
    }

    for (const channel of channels) {
      for (const pps of ppsArr) {
        if (!pps[channel]) {
          pps[channel] = {
            purchaseAmount: 0,
            purchaseCount: 0,
            roas: 0,
            aov: 0,
            cpo: 0,
            cac: 0,
          };
        }
      }
    }

    return ppsArr;
  }, [ppsData, loading, dailyOrderData, dailyChannelData, newVsReturning]);
  const ppsOptions = useMemo(() => {
    if (newVsReturning === "default") {
      return PPS_METRICS_OPTIONS.filter(
        (option) => newVsReturning === "default" && option.value !== "cac"
      );
    }
    return PPS_METRICS_OPTIONS;
  }, [newVsReturning]);

  if (loading) {
    return (
      <div className="relative bg-gray-700 p-6 rounded-lg">
        <div className="h-9 w-2/5 bg-gray-800 rounded-lg animate-pulse"></div>
        <div className="mt-2 h-5 w-1/2 bg-gray-800 rounded-lg animate-pulse"></div>
        <div className="mt-8 animate-pulse bg-gray-800 rounded-lg h-[350px]"></div>
      </div>
    );
  }

  if (!ppsData || ppsData.length === 0) {
    return (
      <CardContainer>
        <DecifyIntegrationPlaceholder />
      </CardContainer>
    );
  }
  return (
    <div className="relative bg-gray-700 p-6 rounded-lg">
      <div className="flex justify-between items-center gap-4">
        <div>
          <div className="flex items-center gap-2">
            <h3 className="h3">Post Purchase Surveys Channel Overview</h3>
            <Tooltip>
              <TooltipTrigger>
                <InformationCircleIcon className="h-5 w-5 text-primary" />
              </TooltipTrigger>
              <TooltipContent asChild>
                <div className="w-80">
                  <p>
                    Only includes data based on survey responses. Metrics will
                    differ compared to click-based tracking
                  </p>
                </div>
              </TooltipContent>
            </Tooltip>
          </div>
          <p className="mt-2">
            Compare channel performance data based on post purchase survey
            responses.
          </p>
        </div>
        <Select
          onValueChange={(value) => setPpsMetric(value)}
          value={ppsMetric}
        >
          <SelectTrigger
            id="pps-metric-select"
            variant="alternate"
            className="w-40"
          >
            <SelectValue placeholder="Select metric" />
          </SelectTrigger>
          <SelectContent>
            {ppsOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="mt-8 bg-gray-800 rounded-lg p-6">
        <PpsChart
          dailyChannelData={dailyChannelData}
          title={``}
          ppsWithOrderData={ppsWithOrderData}
        />
      </div>
    </div>
  );
}
